import Decimal from 'decimal.js' // 具体文件中引入

const obj = {
  getStorage(key) {
    try {
      return uni.getStorageSync(key)
    } catch (e) {
      return null
    }
  },
  randomStr(length) {
    length = length || 8
    return Math.random().toString(36).slice(-length)
  },
  setStorage(key, data) {
    try {
      uni.setStorageSync(key, data)
    } catch (e) {
      throw new Error('setStorage Error')
    }
  },
  removeStorage(key) {
    uni.removeStorage({
      key: key
    })
  },
  getUrlParam(key) {
    const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i')
    const r = window.location.search.substr(1).match(reg)
    if (r != null) {
      return decodeURIComponent(r[2])
    }

    return null
  },
  addUrlParam(key, value, uri) {
    uri = uri || window.location.href
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    var separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
      return uri + separator + key + '=' + value
    }
  },
  isIOS() {
    return uni.getSystemInfoSync().platform === 'ios'
  },
  isAndroid() {
    return uni.getSystemInfoSync().platform === 'android'
  },
  isIOSWeb() {
    return window.__wxjs_is_wkwebview === true
  },
  isPhoneNumber(str) {
    var phoneReg = /^[1]\d{10}$/
    // var phoneReg = /\d{11}/
    return phoneReg.test(str)
  },
  isEmail(str) {
    var reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/
    return reg.test(str)
  },
  // 纳税人识别号
  isTaxNumber(str) {
    const reg = /^[0-9a-zA-Z]{15,20}$/
    return reg.test(str)
  },
  checkNickName(str) {
    const reg = /^[A-Za-z0-9\u4e00-\u9fa5\-\_]{1,15}$/
    return reg.test(str)
  },
  maskPhone(str) {
    if (str != null && str != undefined) {
      var pat = /(\d{3})\d*(\d{4})/
      return str.replace(pat, '$1****$2')
    } else {
      return ''
    }
  },
  now(fmt) {
    return this.formatDate(null, fmt)
  },
  formatPrice(price) {
    return (price / 100).toFixed(2)
  },
  formatDate(time, fmt) {
    if (typeof time === 'string') {
      var t = time.split(/[- :]/)

      // Apply each element to the Date function
      var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
      var date = new Date(d)
    } else {
      var date = new Date()
    }

    // let date = new Date(time)
    var o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      'S': date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
      }
    }
    return fmt
  },
  showShortTime(time) {
    var t = time.split(/[- :]/)
    // Apply each element to the Date function
    var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])

    const now = new Date()

    const diff = now.getTime() - d.getTime()
    const day = Math.floor(diff / (24 * 3600 * 1000))
    var leave1 = diff % (24 * 3600 * 1000)
    var hour = Math.floor(leave1 / (3600 * 1000))
    var leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
    var minute = Math.floor(leave2 / (60 * 1000)) // 计算相差分钟数

    if (day > 4) {
      return this.formatDate(time, 'MM/dd hh:mm')
    } else if (day > 0) {
      return `${day}天${hour}小时前`
    } else if (hour > 0) {
      return `${hour}小时前`
    } else if (minute > 4) {
      return `${minute}分钟前`
    } else {
      return '刚刚'
    }
  },
  /**
  * floatObj 包含加减乘除四个方法，能确保浮点数运算不丢失精度
  *
  * 精度丢失问题（或称舍入误差，其根本原因是二进制和实现位数限制有些数无法有限表示
  * 以下是十进制小数对应的二进制表示
  *      0.1 >> 0.0001 1001 1001 1001…（1001无限循环）
  *      0.2 >> 0.0011 0011 0011 0011…（0011无限循环）
  * 计算机里每种数据类型的存储是一个有限宽度，比如 JavaScript
  使用 64 位存储数字类型，因此超出的会舍去。舍去的部分就是精度丢失的部分。
  *
  * ** method **
  *  add / subtract / multiply /divide
  *
  * ** explame **
  *  0.1 + 0.2 == 0.30000000000000004 （多了 0.00000000000004）
  *  0.2 + 0.4 == 0.6000000000000001  （多了 0.0000000000001）
  *  19.9 * 100 == 1989.9999999999998 （少了 0.0000000000002）
  *
  * floatObj.add(0.1, 0.2) === 0.3
  * floatObj.multiply(19.9, 100) === 1990
  *
  */
  floatObj(a, b, op) {
    var result = null;
    var a = Number(a);
    var b = Number(b);
    switch (op) {
      case 'add':
        result = Decimal(a).add(Decimal(b)).toNumber()
        return result;
      case 'subtract':
        result = Decimal(a).sub(Decimal(b)).toNumber()
        return result;
      case 'multiply':
        result = Decimal(a).mul(Decimal(b)).toNumber()
        return result;
      case 'divide':
        result = Decimal(a).div(Decimal(b)).toNumber()
        return result;
    }
  },
  // 转换为金钱格式 2,000.00
  toMoneyFormat(i, len = 2) {
    try {
      let num = (Number(i)).toFixed(len);
      return (num).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } catch {
      return ''
    }
  },
  getImageSize(url) {
    return new Promise((resolve, reject) => {
      let image = new Image()
      image.onload = function () {
        resolve({
          width: image.width,
          height: image.height,
        })
      }
      image.onerror = function () {
        reject(new Error('error'))
      }
      image.src = url
    })
  },
  // 返回图片的高30rpx时的宽
  async getScaleWidth(img, minrpx = 30) {
    if (img) {
      //minrpx小程序图片的固定高度
      let size = await this.getImageSize(img)
      let scaleWidth = Number(((size.width / size.height) * minrpx).toFixed(2))
      return `${img.split('?scaleWidth=')[0]}?scaleWidth=${scaleWidth}rpx`
    } else {
      return ''
    }
  },
  // 返回图片的宽高比
  async getScaleRate(img) {
    if (img) {
      let size = await this.getImageSize(img)
      let scaleRate = Number(((size.width / size.height)).toFixed(8))
      return `${img.split('?scaleRate=')[0]}?scaleRate=${scaleRate}`
    } else {
      return ''
    }
  },
  getChannelList() {
    return [
      {
        name: '微信小程序',
        value: 'WeChat_mini',
      },
      {
        name: 'APP IOS（苹果商店）',
        value: 'App_ios',
      },
      {
        name: 'APP 安卓（官方/其他应用商店）',
        value: 'App_android_other',
      },
      {
        name: 'APP 安卓（华为商店）',
        value: 'App_android_huawei',
      },
      {
        name: 'APP 安卓（小米商店）',
        value: 'App_android_xiaomi',
      },
      {
        name: 'APP 安卓（字节广告）',
        value: 'App_android_bytedance',
      },
    ]
  },
  getChannelMap() {
    return {
      WeChat_mini: '微信小程序',
      App_ios: 'APP IOS（苹果商店）',
      App_android_other: 'APP 安卓（官方/其他应用商店）',
      App_android_huawei: 'APP 安卓（华为商店）',
      App_android_xiaomi: 'APP 安卓（小米商店）',
      App_android_bytedance: 'APP 安卓（字节广告）'
    }
  },
  channelToCN(channelStr) {
    if (!channelStr) {
      return ''
    }
    let result = channelStr
    this.getChannelList().forEach(i => {
      result = result.replace(i.value, i.name)
    });
    return result
  },
  // 对象序列化，undefined和函数丢失问题
  JSONStringify(option) {
    return JSON.stringify(option,
      (key, val) => {
        // 处理函数丢失问题
        if (typeof val === 'function') {
          return `${val}`;
        }
        // 处理undefined丢失问题
        if (typeof val === 'undefined') {
          return 'undefined';
        }
        return val;
      },
      2
    )
  },
  // 对象序列化解析
  JSONParse(objStr) {
    return JSON.parse(objStr, (k, v) => {
      if (typeof v === 'string' && v.indexOf && v.indexOf('function') > -1) {
        // eval 可能在eslint中报错，需要加入下行注释
        // eslint-disable-next-line
        return eval(`(function(){return ${v}})()`);
      }
      return v;
    });
  },
  JSONPS(obj) {
    return this.JSONParse(this.JSONStringify(obj));
  },
  toMoneyFormat(i, len = 2) {
    try {
      let num = (Number(i)).toFixed(len);
      return (num).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } catch {
      return ''
    }
  },
}

export default obj

import fetch from '../utils/fetch'

export function getMemberCredits(query) {
  return fetch({
    url: `/credits/member/${query.user_id}/logs`,
    method: 'get',
    params: query
  })
}

export function getCreditsCountIndex(query) {
  return fetch({
    url: '/member/pointcount/index',
    method: 'get',
    params: query
  })
}

export function adjustmentCredits(query) {
  return fetch({
    url: `/credits/member/${query.user_id}`,
    method: 'post',
    params: query
  })
}

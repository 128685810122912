<template>
    <div class="boxC v_flex_justify_between v_flex_align_center">
      <div>
        <div>多币</div>
        <div>xxxx</div>
      </div>
      <div class="wgt-hd">
        <span class="title">转化多币兑换更多商品</span>
      </div>
    </div>
  </template>
  <script>
  import config from './config'
  export default {
    name: 'zhuanhua',
    wgtName: '多币转化页面入口',
    wgtDesc: '',
    wgtIcon: 'wgt-showcase',
    config: config,
    props: {
      value: [Object, Array]
    }
  }
  </script>
  <style lang="scss" scoped>
  .boxC{
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background-color: #fff;
  }
  </style>
  
<template>
    <span class="product-price">
      <span class="price">
        <template v-if="money"> <span class="symbol">￥</span>{{ formatPrice(money) }} </template>
        <template v-if="isCredits && credits">
          <template v-if="money">或</template>
          <template> {{ credits }}<span class="symbol-text">多币</span> </template>
        </template>
        <template v-if="!money && !credits"> <span class="symbol">￥</span>0 </template>
      </span>
    </span>
  </template>
  
  <script>
  export default {
    name: 'PriceExchangeDisplay', 
    props: {
      info: {
        type: Object,
        default() {
          return {}
        },
      },
      moneyKey: {
        type: String,
      },
      creditsKey: {
        type: String,
      },
      prefix: {
        type: String,
        default() {
          return ''
        },
      },
      linePrefix: {
        type: String,
        default() {
          return 'discount_'
        },
      },
      isCredits: {
        //是否需要多币
        type: Boolean,
        default: true,
      },
      unit: {
        type: Number,
        default() {
          return 1
        },
      }
    },
    methods: {
      formatPrice(price) {
        return this.$tool.toMoneyFormat(this.$tool.floatObj(price, Number(this.unit), 'divide'))
        // return this.$tool.toMoneyFormat(price || 0)
      },
    },
    computed: {
      money() {
        return  Number(this.info[this.moneyKey || this.prefix + 'price'] || 0)
      },
      credits() {
        return Number(this.info[this.creditsKey || this.prefix + 'credits'] || 0)
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .product-price {
    flex: 0 0 150px;
  }
  .price {
    // font-weight: bold;
    // color: #FF294A;
  }
  span {
    margin: 0;
    padding: 0;
  }
  .line-price {
    font-size: 80%;
    color: #a0a0a0;
    text-decoration: line-through;
  }
  .symbol {
    font-size: 80%;
  }
  .symbol-text {
    font-size: 70%;
  }
  </style>
  
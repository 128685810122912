<template>
    <span class="v_flex_align_center">
      <el-input-number
        class="v_w150 v_mr5"
        :size="size"
        :precision="2"
        v-model="priceFloat"
        :disabled="disabled"
        :max="max"
        :min="min"
        @blur="fblur"
      ></el-input-number
      >
      <div class="v_mr10">人民币</div>
      <!-- <div class="v_ml10 v_mr10">+</div> -->
      <el-input-number
        :disabled="credits_disabled ? !credits_disabled : disabled"
        class="v_w150 v_mr5"
        v-model.number="credits"
        :precision="0"
        :max="credits_max"
        :min="credits_min"
        :size="size"
        @blur="fblur"
      ></el-input-number
      >多币 
    </span>
  </template>
  <script>
  export default {
    name: 'SpMixPrice',
    props: {
      size: {
        default() {
          return 'small'
        },
      },
      max: {
        default: 999999999,
      },
      min: {
        default: 0,
      },
      credits_max: {
        default: 999999999,
      },
      credits_min: {
        default: 0,
      },
      initMoney: {},
      initCredits: {},
      disabled: {
        type: Boolean,
        default: false,
      },
      credits_disabled: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        priceFloat: 0,
        credits: 0,
      }
    },
    mounted() {
      this.priceFloat = this.initMoney || 0
      this.credits = this.initCredits || 0
    },
    computed: {},
    watch: {
      priceFloat(val) {
        this.$emit('moneyChange', val || 0)
      },
      credits(val) {
        this.credits = parseInt(val || 0);
        this.$emit('creditsChange', parseInt(val || 0))
      },
      initMoney(val) {
        this.priceFloat = this.initMoney || 0
      },
      initCredits(val) {
        this.credits = this.initCredits || 0
      },
    },
    methods: {
        fblur(){
           this.$emit('fblur')
        },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .money-input {
    width: 160px;
    margin-right: 4px;
  }
  </style>
  
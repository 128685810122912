import { pickBy } from '@/utils'
export default {
  name: 'zhuanhua',
  setting: [
    { label: '说明', key: 'title', component: 'text', value: '已登录且已授权状态下：显示多币余额，点击跳转到多币转化页面；未登录或未授权状态下：进入登录或授权流程。' },
  ],
  transformIn: (v) => {
    const { name, base, config } = v
    return {
      name,
      ...base,
      ...config
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          padded: 'padded'
        })
      },
      config: (v) => {
        return pickBy(v, {
          fixTop: 'fixTop',
          placeholder: 'placeholder'
        })
      }
    })
  }
}
